import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldCheckbox, FieldSelectWithSearch, FieldText } from 'components/form';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { AccountSelectorField } from '../../cabinet/header/account-selector';

const BookingSurrogateAgency = observer(({ formik, readOnly }) => {
    const [agenciesList, setAgenciesList] = useState([]);

    const setAgencyContractKindById = (list, agencyId) => {
        const selected = list.find(({ id }) => id === agencyId);

        formik.setFieldValue(
            'surrogateActiveAgencyContractKind',
            selected?.isSurrogate === false ? selected.contractKind : null
        );
    };

    useEffect(() => {
        API.get({
            url: apiMethods.SURROGATE_AGENCIES,
            success: (list) => {
                setAgenciesList(list);
                setAgencyContractKindById(list, formik.values.surrogateAgencyId);
            },
        });
    }, []);

    const [accounts, currencies] = useMemo(() => {
        if (formik.values.surrogateAgencyId) {
            const selected = agenciesList.find(({ id }) => id === formik.values.surrogateAgencyId);
            if (selected) {
                return [
                    selected.contractKind === 'VirtualAccountOrCreditCardPayments' ? selected.accounts : [],
                    selected.availableCurrencies,
                ];
            }
        }
        return [[], []];
    }, [formik.values.surrogateAgencyId, agenciesList]);

    const agents = useMemo(() => {
        if (formik.values.surrogateAgencyId) {
            const selected = agenciesList.find(({ id }) => id === formik.values.surrogateAgencyId);
            if (selected) {
                return selected.agents?.map(({ id, name }) => ({ value: id, text: name }));
            }
        }
    }, [formik.values.surrogateAgencyId, agenciesList]);

    let options = agenciesList.map(({ name, id, isSurrogate }) => ({
        value: id,
        text: name,
        postfix: (
            <>
                <span className="agency-id">ID{id}</span>
                <span className={'tag ' + (isSurrogate ? 'tag-surrogate' : 'tag-active')}>
                    {isSurrogate ? 'Surrogate' : 'Active'}
                </span>
            </>
        ),
    }));

    const setAgencyValue = (agencyId) => {
        setAgencyContractKindById(agenciesList, agencyId);
        formik.setFieldValue('currency', '');
        formik.setFieldValue('surrogateAgentId', '');
        formik.setFieldValue('surrogateAgentIdCode', null);
    };

    if (!agenciesList.length) {
        return null;
    }

    if (!formik.values.isSurrogateSearch && readOnly) {
        return null;
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FieldCheckbox
                    formik={formik}
                    label="For Other Agency"
                    id="isSurrogateSearch"
                    disabled={readOnly}
                />
            </div>
            {formik.values.isSurrogateSearch ? (
                <div className="row">
                    <FieldSelectWithSearch
                        formik={formik}
                        id="surrogateAgencyId"
                        placeholder="Select Agency"
                        options={options}
                        label="Agency"
                        className="size-two agency-selector"
                        readOnly={readOnly}
                        disabled={readOnly}
                        setValue={setAgencyValue}
                        searchWithinId
                    />
                    {formik.values.surrogateActiveAgencyContractKind ? (
                        <FieldSelectWithSearch
                            formik={formik}
                            id="surrogateAgentId"
                            placeholder="Select Agent"
                            options={agents}
                            label="Agent"
                            className="size-two"
                            readOnly={readOnly}
                            disabled={readOnly}
                            key={formik.values.surrogateAgencyId}
                        />
                    ) : null}
                    <AccountSelectorField
                        formik={formik}
                        disabled={!formik.values.surrogateAgencyId || readOnly}
                        accounts={accounts}
                        currencies={currencies}
                    />
                </div>
            ) : null}
        </>
    );
});

export default BookingSurrogateAgency;
