import React from 'react';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { $accommodation, $notifications, $auth } from 'stores';
import { CachedForm, FieldText } from 'components/form';
import ModalTemplate from 'common/template/modal-template';
import { price } from '../../../simple';
import { calculateCustomPrice } from '../parts/booking-summary';

const calcPrice = (price, percent) => {
    return ((price * (100 + percent)) / 100).toFixed(2);
};

const validationSchema = Yup.object().shape({
    markup: Yup.number().moreThan(0, 'Markup cannot be <=0'),
    decreaseMarkupReason: Yup.string().test(
        'trim',
        'This field cannot be empty or contain only spaces',
        function (value) {
            const { path, createError } = this;

            if (!value) return true;

            if (value.length && !value.trim().length) {
                return createError({
                    path,
                    message: 'This field cannot be empty or contain only spaces',
                });
            }
            return true;
        }
    ),
});

const EditMarkupModal = observer((props) => {
    const { booking } = props;

    if (!props.isOpen || !booking) return null;

    const canDecrease = $auth.agencySettings.canAgentDecreaseSurrogateBookingMarkup;

    const basePrice = booking.rate.finalPrice.amount;
    const markup = booking.rate.totalMarkupPercent;
    const rawPrice = (basePrice * 100) / (100 + markup);

    const isMultiRoom = booking.rooms.length > 1;

    const initialValues = {
        price: basePrice,
        markup: markup,
    };

    if ($accommodation.search.request.customPrice) {
        initialValues.price = String(calcPrice(rawPrice, $accommodation.search.request.markup || 0));
        initialValues.markup = String($accommodation.search.request.markup || 0);
    }

    const submit = (values) => {
        if (canDecrease) {
            const rawPriceWithMinMarkup = rawPrice * 1.0001;

            if (values.price < rawPriceWithMinMarkup - 0.01 || values.markup < 0) {
                $notifications.addNotification(
                    `Markup should not be less than 0% and Final price should not be less than ${rawPriceWithMinMarkup.toFixed(
                        2
                    )}`
                );
                return;
            }
            if (!values.decreaseMarkupReason && values.markup < markup) {
                $notifications.addNotification(`Markup decrease reason should be provided.`);
                return;
            }
        } else {
            if (values.price < basePrice || values.markup < markup) {
                $notifications.addNotification(
                    `Markup should not be less than ${markup}% and Final price should not be less than ${basePrice}`
                );
                return;
            }
        }
        if (values.markup === undefined || values.markup === '' || !values.price || values.markup == markup) {
            $accommodation.setCustomMarkup(null);
            props.onClose();
            return;
        }
        $accommodation.setCustomMarkup(
            parseFloat(values.markup),
            parseFloat(values.price),
            values.markup < markup ? values.decreaseMarkupReason : undefined
        );
        props.onClose();
    };

    const onPriceChange = (event, formik) => {
        if (event.target.value?.split('.')?.[1]?.length > 2) {
            event.target.value = parseFloat(event.target.value).toFixed(2);
        }

        const value = parseFloat(event.target.value);

        if (value && value >= (canDecrease ? rawPrice : basePrice)) {
            formik.setFieldValue('markup', (((value - rawPrice) / rawPrice) * 100).toFixed(2));
        } else {
            formik.setFieldValue('markup', markup);
        }
    };

    const onMarkupChange = (event, formik) => {
        if (event.target.value?.split('.')?.[1]?.length > 2) {
            event.target.value = parseFloat(event.target.value).toFixed(2);
        }

        const value = parseFloat(event.target.value);

        if (value !== undefined && value >= (canDecrease ? 0 : markup)) {
            formik.setFieldValue('price', calcPrice(rawPrice, value));
        } else {
            formik.setFieldValue('price', basePrice);
        }
    };

    return (
        <ModalTemplate addClass="wide" {...props}>
            <h2>Room &amp; Total Cost Settings</h2>

            <CachedForm
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                render={(formik) => (
                    <div className="form">
                        <div style={{ marginBottom: 20 }}>Please enter new total cost price or markup</div>
                        {isMultiRoom &&
                            booking.rooms.map((room, _index) => (
                                <div style={{ marginBottom: 20 }} key={_index}>
                                    <FieldText
                                        formik={formik}
                                        label={'Room ' + (_index + 1) + ' Cost'}
                                        Icon={<>{booking.rate.finalPrice.currency}</>}
                                        disabled
                                        value={calculateCustomPrice(
                                            room.rate?.finalPrice.amount,
                                            markup,
                                            formik.values.markup || markup
                                        )}
                                    />
                                </div>
                            ))}
                        <div style={{ marginBottom: 20 }}>
                            <FieldText
                                formik={formik}
                                id="price"
                                label="Total Cost"
                                placeholder="Enter New Total Cost"
                                numeric="."
                                Icon={<>{booking.rate.finalPrice.currency}</>}
                                onChange={(event) => onPriceChange(event, formik)}
                            />
                        </div>
                        <div>
                            <FieldText
                                formik={formik}
                                id="markup"
                                label={`Markup (${canDecrease ? 0 : markup}% or more)`}
                                placeholder="Enter Markup"
                                numeric="."
                                Icon={<>%</>}
                                onChange={(event) => onMarkupChange(event, formik)}
                            />
                        </div>
                        {canDecrease ? (
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <FieldText
                                        formik={formik}
                                        id="decreaseMarkupReason"
                                        label="Approval"
                                        placeholder="Please Enter Markup Decrease Reason"
                                        maxLength={250}
                                        disabled={
                                            (!formik.values.markup && formik.values.markup !== 0) ||
                                            formik.values.markup >= markup
                                        }
                                    />
                                </div>
                                <span style={{ marginLeft: 'auto', color: '#777777' }}>
                                    {formik.values.decreaseMarkupReason?.length || 0}/250
                                </span>
                            </div>
                        ) : null}
                        <div className="bottom" style={{ display: 'flex', gap: '25px' }}>
                            <button className="button" style={{ marginRight: 0 }} onClick={props.onClose}>
                                Cancel
                            </button>
                            <button
                                className={
                                    'button main' +
                                    __class(
                                        !formik.values.price || !formik.values.price || !formik.isValid,
                                        'disabled'
                                    )
                                }
                                disabled={!formik.values.price || !formik.values.price || !formik.isValid}
                                type="submit"
                                style={{ marginRight: 0 }}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                )}
            />
        </ModalTemplate>
    );
});

export default EditMarkupModal;
