import React from 'react';
import FieldDestination from 'components/complex/field-destination';
import { FieldDatepicker, FieldText } from 'components/form';
import { Flag } from 'components/simple';
import PeopleDropdown from 'components/complex/room-details-dropdown';
import ResidencyDropdown from 'components/complex/residency-dropdown';
import { countPassengers } from 'simple/logic';

const countryElement = (code) => (
    <span className="element">
        <Flag code={code} />
    </span>
);

const generateCountriesValue = (values) => {
    if (!values.nationalityCode || !values.residencyCode) return null;

    return (
        <span className="flags">
            {countryElement(values.nationalityCode)}
            {values.nationalityCode !== values.residencyCode && countryElement(values.residencyCode)}
        </span>
    );
};

const SearchFormShortPart = ({ formik }) => (
    <div className="form short">
        <FieldDestination formik={formik} id="destination" placeholder="Destination" short />
        <FieldDatepicker
            formik={formik}
            id="dates"
            first="checkInDate"
            second="checkOutDate"
            placeholder="Dates"
            future
            short
        />
        <FieldText
            noInput
            formik={formik}
            id="room"
            Dropdown={PeopleDropdown}
            Icon={<span className="icon icon-search-guests" />}
            value={true}
            ValueObject={
                <>
                    {__plural(countPassengers(formik.values), 'Guest')}
                    <span className="hide-mobile">
                        {' • '}
                        {__plural(formik.values.roomDetails.length, 'Room')}
                    </span>
                </>
            }
        />
        <FieldText
            noInput
            formik={formik}
            id="residencyPicker"
            placeholder="Select"
            additionalFieldForValidation="nationality"
            Dropdown={ResidencyDropdown}
            ValueObject={generateCountriesValue(formik.values)}
            value={formik.values.nationalityCode && formik.values.residencyCode}
        />
        <div className="button-holder">
            <button type="submit" className="main button">
                <span className="icon icon-search-white" />
            </button>
        </div>
    </div>
);

export default SearchFormShortPart;
