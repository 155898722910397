import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { authorized } from 'htcore';
import { FORM_NAMES } from 'components/form';
import { searchSaveForm } from 'tasks/accommodation/search-create';
import { searchFormValuesCorrection } from 'tasks/accommodation/search-form-formatter';
import { $ui } from 'stores';

const BANNERS = [
    '/images/landing/footer/big-image/ni-1.jpg',
    '/images/landing/footer/big-image/ni-2.jpg',
    '/images/landing/footer/big-image/ni-3.jpg',
];

let banner;

const FooterBanner = observer(() => {
    const navigate = useNavigate();

    if (!banner) {
        banner = BANNERS[Math.trunc(Math.random() * (BANNERS.length - 0.01))];
    }

    const startSearch = () => {
        if (!authorized()) {
            navigate('/signup');
            return;
        }
        const destinationName = 'North Island, A Luxury Collection Resort';
        let destinationHtId = 'Accommodation_27011507';

        if (__devEnv || __localhost) {
            destinationHtId = 'Accommodation_20071406';
        }

        const form = searchFormValuesCorrection(
            $ui.formCache?.[FORM_NAMES.SearchForm]
                ? JSON.parse($ui.formCache[FORM_NAMES.SearchForm])
                : {
                      roomDetails: [
                          {
                              adultsNumber: 2,
                              childrenAges: [],
                          },
                      ],
                  }
        );

        const values = {
            ...form,
            destinationInput: destinationName,
            destination: destinationHtId,
        };
        $ui.formCache[FORM_NAMES.SearchForm] = JSON.stringify(values);
        searchSaveForm(values, () => null, navigate);
    };

    return <img className="image pointer" src={banner} alt="The Ritz Carlton, Dubai" onClick={startSearch} />;
});

export default FooterBanner;
