import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { useNavigate } from 'react-router-dom';
import { $ui } from 'stores';
import { FORM_NAMES } from '../../../components/form';
import { searchFormValuesCorrection } from '../../../tasks/accommodation/search-form-formatter';

const BookingItinerary = ({
    itineraryNumber,
    isContinuePossible,
    bookingCurrency,
    surrogateAgencyName,
    surrogateAgencyId,
    surrogateAgentName,
    surrogateAgentId,
    isCurrentAgent,
}) => {
    const navigate = useNavigate();

    const addBooking = () => {
        API.put({
            url: apiMethods.PREFERRED_CURRENCY,
            body: {
                preferredCurrency: bookingCurrency,
            },
        });
        $ui.setIntMode(
            itineraryNumber,
            !isCurrentAgent
                ? {
                      surrogateAgencyName,
                      surrogateAgencyId,
                      surrogateAgentName,
                      surrogateAgentId,
                      currency: bookingCurrency,
                  }
                : {}
        );

        const form = searchFormValuesCorrection(
            $ui.formCache?.[FORM_NAMES.SearchForm]
                ? JSON.parse($ui.formCache[FORM_NAMES.SearchForm])
                : {
                      roomDetails: [
                          {
                              adultsNumber: 2,
                              childrenAges: [],
                          },
                      ],
                  }
        );

        let values;

        if (surrogateAgencyName && !isCurrentAgent) {
            values = {
                ...form,
                isSurrogateSearch: true,
                surrogateAgencyId,
                surrogateAgencyIdCode: surrogateAgencyName,
                surrogateAgentId,
                surrogateAgentIdCode: surrogateAgentName,
                currency: bookingCurrency,
            };
        } else {
            values = {
                ...form,
                isSurrogateSearch: false,
                surrogateAgencyId: null,
                surrogateAgencyIdCode: null,
                surrogateAgentId: null,
                surrogateAgentIdCode: null,
                currency: null,
            };
        }
        $ui.formCache[FORM_NAMES.SearchForm] = JSON.stringify(values);
        navigate('/search');
    };

    return (
        <div className="itinerary-number">
            <p>
                <span>Itinerary Number:</span> <strong>{itineraryNumber}</strong>
            </p>
            {isContinuePossible && (
                <button className="button main" onClick={addBooking}>
                    Add New Booking to Itinerary
                    {surrogateAgencyName && !surrogateAgentName ? (
                        <>
                            <br />
                            {` for ${surrogateAgencyName}`}
                        </>
                    ) : null}
                </button>
            )}
        </div>
    );
};

export default BookingItinerary;
