import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date } from 'simple';
import { $accommodation } from 'stores';
import { observer } from 'mobx-react';

const Deadline = observer(
    ({ roomContractSet, searchId, htId, loadedDeadline, setLoadedDeadline, checkInDate }) => {
        const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
        const isRequestPossible = !loadedDeadline && !roomContractSet.isNonRefundable;

        let htOnly;
        if (
            $accommodation.search.request.surrogateActiveAgencyContractKind === 'OfflineOrCreditCardPayments'
        ) {
            htOnly = (
                <div style={{ marginTop: 6, fontSize: '93%' }}>
                    Can be processed on behalf of&nbsp;Happy&nbsp;Travel&nbsp;only
                </div>
            );
        }

        const request = (event) => {
            event.stopPropagation();
            API.get({
                url: apiMethods.REQUEST_DEADLINE(searchId, htId, roomContractSet.id),
                success: (data) => {
                    setLoadedDeadline(data || {});
                },
            });
        };

        if (isRequestPossible && roomContractSet.deadline?.isFinal !== true)
            return (
                <div onClick={request}>
                    Show Cancellation Deadline <i className="icon icon-info" />
                </div>
            );

        if (roomContractSet.isNonRefundable) {
            return (
                <div>
                    <span className="non-refundable">Non-Refundable</span>
                    {htOnly}
                </div>
            );
        }

        if (deadline) {
            if (date.isFuture(deadline))
                return (
                    <div>
                        <span className="refundable">Refundable </span>
                        Before {date.format.day(deadline, checkInDate)}
                    </div>
                );

            return (
                <div>
                    <span className="within-deadline">Within Deadline</span>
                    {htOnly}
                </div>
            );
        }

        return null;
    }
);

export default Deadline;
