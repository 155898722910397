import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { $accommodation, $ui } from 'stores';

const SurrogateAgencyNotification = observer(() => {
    const navigate = useNavigate();

    const onDismiss = () => {
        $accommodation.clearSearch();
        $ui.resetItnMode();
        navigate('/search');
    };

    if ($ui.itnMode || !$accommodation.search.request.surrogateAgencyId) {
        return null;
    }

    const agencyAndAgentName = [
        $accommodation.search.request.surrogateAgencyName,
        $accommodation.search.request.surrogateAgentName,
    ]
        .filter(Boolean)
        .join(', ');
    const agencyType = $accommodation.search.request.surrogateAgentName ? 'Active' : 'Surrogate';

    return (
        <div className="custom-search-notification">
            <section>
                <div>
                    <span className="icon-information icon" />
                    <div>
                        <span className="hide-mobile">
                            <strong>Notification: </strong>
                            This Booking is for the {agencyType} Agency <b>{agencyAndAgentName}</b> in{' '}
                            <b>{$accommodation.search.request.currency}</b> Currency
                        </span>
                    </div>
                </div>
                <button className="button" onClick={onDismiss}>
                    Dismiss
                </button>
            </section>
        </div>
    );
});

export default SurrogateAgencyNotification;
