import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { $accommodation, $ui } from 'stores';

const ItineraryNotification = observer(() => {
    const navigate = useNavigate();

    const onDismiss = () => {
        $accommodation.clearSearch();
        $ui.resetItnMode();
        navigate('/search');
    };

    if (!$ui.itnMode) {
        return null;
    }

    const agencyAndAgentName = [
        $ui.itnModeAdditionalData.surrogateAgencyName,
        $ui.itnModeAdditionalData.surrogateAgentName,
    ]
        .filter(Boolean)
        .join(', ');
    const agencyType = $ui.itnModeAdditionalData.surrogateAgentName ? 'Active' : 'Surrogate';

    return (
        <div className="custom-search-notification">
            <section>
                <div>
                    <span className="icon-information icon" />
                    <div>
                        <span className="hide-mobile">
                            <strong>Notification: </strong> This is Itinerary booking
                            {$ui.itnModeAdditionalData?.surrogateAgencyName ? (
                                <>
                                    {' '}
                                    for {agencyType} Agency <strong>{agencyAndAgentName}</strong> in{' '}
                                    {$ui.itnModeAdditionalData.currency}.
                                </>
                            ) : (
                                '.'
                            )}
                            &nbsp;
                        </span>
                        <span>
                            All Bookings will be added to <strong> {$ui.itnMode}</strong>
                            <span className="hide-desktop">
                                {$ui.itnModeAdditionalData?.surrogateAgencyName ? (
                                    <>
                                        For {agencyType} Agency <strong>{agencyAndAgentName}</strong> in{' '}
                                        {$ui.itnModeAdditionalData.currency}.
                                    </>
                                ) : null}
                            </span>
                        </span>
                    </div>
                </div>
                <button className="button" onClick={onDismiss}>
                    Dismiss
                </button>
            </section>
        </div>
    );
});

export default ItineraryNotification;
