import React from 'react';
import { Flag } from 'components/simple';
import { Highlighted } from 'simple';

const FieldSelectDropdown = ({ setValue, options, focusIndex, caption, value }) => {
    return (
        <div className="select dropdown">
            <div className="scroll">
                {caption}
                {options?.map((item, index) => (
                    <div
                        onClick={() => setValue(item)}
                        className={'line' + __class(focusIndex === index, 'focused')}
                        key={index}
                    >
                        {item.flag && <Flag code={item.flag} />}
                        <span className="text-holder">
                            {typeof item.text === 'string' ? (
                                <Highlighted str={item.text} highlight={value} />
                            ) : (
                                item.text
                            )}
                        </span>
                        {item.postfix}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FieldSelectDropdown;
