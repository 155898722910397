import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { SEARCH_STATUSES } from 'enum';
import { $accommodation } from 'stores';
import { generateFiltersObject, generateSortersObject } from 'tasks/utils/accommodation-filtering';
import { searchCreate } from './search-create';
import { searchSecondStepLoadWithNewFilters, searchSecondStepLoadWithNewOrder } from './search-rooms-loaders';

const POLLING_DURATION = 10 * 60 * 1000;
const REQUEST_INTERVAL = 7000;
export const PAGE_SIZE = 30;

const requestSearchResultsPage = (page = 0, callback = () => {}) => {
    const { search, selected } = $accommodation;
    const { filters, sorter } = selected;

    if (!search.id) return;

    let isWideSearch = search.request.htIds[0].includes('Locality_');

    if (isWideSearch && page === 0) {
        API.get({
            url: apiMethods.A_SEARCH_ONE_RESULT_CHAINS(search.id),
            success: (result) => {
                $accommodation.setSearchChains(result);
            },
        });
        API.get({
            url: apiMethods.A_SEARCH_ONE_RESULT_DISTRICTS(search.id),
            success: (result) => {
                $accommodation.setSearchDistricts(result);
            },
        });
    }

    API.get({
        url: apiMethods.A_SEARCH_ONE_RESULT(search.id),
        body: {
            top: PAGE_SIZE,
            skip: page * PAGE_SIZE,
            ...generateFiltersObject(filters),
            ...generateSortersObject(sorter),
        },
        ignoreErrors: true,
        success: (result) => {
            callback();
            $accommodation.setSearchResult(result, page);
        },
        error: () => $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.BROKEN }),
        after: () => $accommodation.setSearchIsLoading(false),
    });
};

export const getSearchState = (forced, id) => {
    const { search } = $accommodation;

    const searchId = id || search.id;

    if (!searchId) {
        return;
    }

    API.get({
        url: apiMethods.A_SEARCH_ONE_CHECK(searchId),
        ignoreErrors: true,
        success: (data) => {
            $accommodation.searchChecked();
            if (SEARCH_STATUSES.isReadyToLoad(data.taskState) || forced) {
                if (!data.resultCount && SEARCH_STATUSES.isFinished(data.taskState)) {
                    $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.BROKEN });
                    $accommodation.setSearchIsLoading(false);
                    return;
                }
                if (search.resultCount !== data.resultCount || search.taskState !== data.taskState) {
                    requestSearchResultsPage(0, () => {
                        $accommodation.updateSearchResultStatus(data);
                    });
                }
            }
        },
        error: () => $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.BROKEN }),
    });
};

const subscribeSearchResults = () => {
    const { search } = $accommodation;

    let searchIsCreating = false;

    if (!search.id && $accommodation.search.taskState === SEARCH_STATUSES.EMPTY) {
        searchIsCreating = true;
        searchCreate()?.finally(() => {
            searchIsCreating = false;
        });
    }

    if (SEARCH_STATUSES.isPending($accommodation.search.taskState)) {
        getSearchState();
    }

    return setInterval(async () => {
        if (
            !$accommodation.search?.id &&
            !searchIsCreating &&
            SEARCH_STATUSES.EMPTY === $accommodation.search.taskState
        ) {
            searchIsCreating = true;
            try {
                await searchCreate();
            } finally {
                searchIsCreating = false;
            }
        }

        const { search } = $accommodation;

        if (!search.id) {
            // todo: detect here if search session is expired
            return;
        }

        if (!SEARCH_STATUSES.isPending(search.taskState)) {
            return;
        }

        if (search.lastCheckedAt - search.createdAt > POLLING_DURATION) {
            $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.TIMEOUT });
            return;
        }

        if (!window.searchSocketConnected) {
            getSearchState();
        }
    }, REQUEST_INTERVAL);
};

const searchLoadPage = (page = 0) => {
    window.scrollTo(0, 0);
    requestSearchResultsPage(page);
};

const searchLoadWithNewFilters = (values) => {
    $accommodation.setSearchSelectedFilters(values);
    $accommodation.setSearchIsLoading(true);
    if ($accommodation.selected?.accommodation?.htId) {
        searchSecondStepLoadWithNewFilters(values);
    }
    requestSearchResultsPage();
};

const searchLoadWithNewOrder = (values) => {
    $accommodation.setSearchIsLoading(true);
    $accommodation.setSearchSelectedSorter(values);
    if ($accommodation.selected?.accommodation?.htId) {
        searchSecondStepLoadWithNewOrder(values);
    }
    requestSearchResultsPage();
};

export { subscribeSearchResults, searchLoadPage, searchLoadWithNewFilters, searchLoadWithNewOrder };
