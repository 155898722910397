import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import FieldDestination from 'components/complex/field-destination';
import { FieldDatepicker, FieldText } from 'components/form';
import PeopleDropdown from 'components/complex/room-details-dropdown';
import { countPassengers } from 'simple/logic';
import FieldCountry from 'components/complex/field-country';
import BookingSurrogateAgency from '../booking/booking-surrogate-agency';
import { $auth, $notifications, $ui } from 'stores';

const SearchFormFullsizePart = observer(({ formik }) => {
    const adults = countPassengers(formik.values, 'adultsNumber');
    const children = countPassengers(formik.values, 'childrenNumber');
    const rooms = formik.values.roomDetails.length;

    const showSurrogateAgency = $auth.activeAgency?.canUseSurrogateAgency;

    const nights = useMemo(() => {
        const { checkInDate, checkOutDate } = formik.values;
        if (checkInDate && checkOutDate) {
            const diff = Math.abs(new Date(checkOutDate) - new Date(checkInDate));
            return Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        return 0;
    }, [formik.values]);

    const searchButtonClick = () => {
        let text;
        if (!formik.values.destination) {
            text = 'Please choose a hotel or a destination to start the search';
        } else if (!formik.values.nationality || !formik.values.residency) {
            text = 'Please select guests nationality and residency to start the search';
        } else if (formik.values.isSurrogateSearch && !formik.values.surrogateAgencyId) {
            text = 'Please select an Agency for booking as other agency';
        } else if (formik.values.surrogateActiveAgencyContractKind && !formik.values.surrogateAgentId) {
            text = 'Please select an Agent for Active Agency booking';
        } else if (formik.values.isSurrogateSearch && !formik.values.currency) {
            text = 'Please select a currency';
        }
        if (text) {
            $notifications.addNotification(text, null, 'information');
        }
    };

    return (
        <div className="form-block lg">
            <div className="title">
                <h1>Find a dream stay for the most discerning travelers</h1>
                <span className="text">Elevate your services with our best offers</span>
            </div>

            <div className="form fullsize">
                <FieldDestination
                    formik={formik}
                    id="destination"
                    label="Destination, Hotel Name, Location or Landmark"
                    placeholder="Destination or Hotel Name"
                />

                <div className="row">
                    <FieldDatepicker
                        formik={formik}
                        id="dates"
                        first="checkInDate"
                        second="checkOutDate"
                        label={
                            <>
                                Dates •{' '}
                                <b style={{ color: '#231f20', fontWeight: 600 }}>
                                    {__plural(nights, 'Night')}
                                </b>
                            </>
                        }
                        placeholder="Dates"
                        future
                    />
                    <FieldText
                        formik={formik}
                        id="room"
                        label="Guests • Rooms"
                        placeholder="Choose options"
                        className="guests-field"
                        Dropdown={PeopleDropdown}
                        Icon={children > 1 ? null : <span className="icon icon-search-guests" />}
                        value={[
                            __plural(adults, 'Adult'),
                            ...(children ? [children + (children === 1 ? ' Child' : ' Children')] : []),
                            __plural(rooms, 'Room'),
                        ].join(' • ')}
                    />
                </div>
                <div className="row">
                    <FieldCountry
                        formik={formik}
                        id="nationality"
                        anotherField="residency"
                        label="Nationality"
                        placeholder="Choose your nationality"
                        className="size-two"
                        clearable
                    />
                    <FieldCountry
                        formik={formik}
                        id="residency"
                        anotherField="nationality"
                        label="Residency"
                        placeholder="Choose your residency"
                        className="size-two"
                        clearable
                    />
                </div>

                {showSurrogateAgency ? (
                    <BookingSurrogateAgency formik={formik} readOnly={Boolean($ui.itnMode)} />
                ) : null}

                <div className="field">
                    <div className="inner button-holder">
                        <button
                            type="submit"
                            className="lg-button button-primary"
                            onClick={searchButtonClick}
                        >
                            Search Accommodations
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SearchFormFullsizePart;
