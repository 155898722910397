import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldArray } from 'formik';
import { date, GroupRoomTypesAndCount, price } from 'simple';
import { Loader } from 'components/simple';
import { useTitle } from 'htcore';
import {
    CachedForm,
    FORM_NAMES,
    FieldText,
    FieldCheckbox,
    FieldSelect,
    FieldChildren,
    FieldTextarea,
} from 'components/form';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { accommodationBookingValidator } from 'components/form/validation';
import { Allotment } from 'components/accommodation';
import transliterate from 'components/external/transliterate';
import ViewFailed from 'common/misc/view-failed';
import { PAYMENT_METHODS } from 'enum';
import BookingSummary from '../parts/booking-summary';
import taskSubmitBookingForm from 'tasks/booking/booking-submit';
import { loadAgency } from 'tasks/utils/agent-settings';
import PaymentMethodSelector from './booking-payment-method-selector';
import CustomSearchNotification from '../custom-search-notification';
import SurrogateBookingConfirmationModal from './surrogate-booking-confirmation-modal';
import { $accommodation, $auth, $payment, $ui } from 'stores';

const AccommodationBookingPage = observer(() => {
    useTitle('Accommodation Booking');
    const [modalVisibility, setModalVisibility] = useState(false);
    const navigate = useNavigate();

    const { request } = $accommodation.search;

    useEffect(() => {
        $accommodation.setBookingRequest(null);
        if (!$auth.agency) {
            loadAgency();
        }
    }, []);

    const isApiClient = $auth.activeAgency?.isApiClient;

    if (!$accommodation.selected?.accommodationFinal?.accommodation?.htId)
        return <ViewFailed button="View Other Options" link="/search/results" />;

    let accommodation = $accommodation.selected.accommodationFinal.accommodation,
        baseInfo = $accommodation.selected.accommodationFinal,
        contract = $accommodation.selected.roomContractSet,
        initialValues = {
            room:
                contract?.rooms?.map((item) => ({
                    passengers: [
                        ...Array(item?.adultsNumber),
                        ...Array(item?.childrenAges.length).fill({
                            title: 'Mr',
                        }),
                    ],
                })) || [],
            accepted: true,
            itineraryNumber: '',
        };

    if (!contract) return null;

    const isOnRequestOnly = Boolean(contract.rooms.find((item) => item.isAvailableImmediately === false));

    const isRefundable =
        !contract.isNonRefundable && (contract.deadline.date ? date.isFuture(contract.deadline.date) : true);

    const surrogateButtonText = useMemo(() => {
        if (request.surrogateActiveAgencyContractKind === 'OfflineOrCreditCardPayments') {
            if (isRefundable) {
                return 'Book';
            } else {
                return 'Book & Pay from Happy Travel Account';
            }
        } else if (request.surrogateAgentId) {
            if (isRefundable) {
                return 'Book';
            } else {
                return 'Book & Pay as ' + request.surrogateAgencyName;
            }
        } else {
            return 'Book & Pay as ' + request.surrogateAgencyName;
        }
    }, [request.surrogateAgentId, request.surrogateAgencyName, request.surrogateActiveAgencyContractKind]);

    const ContinueButton = useCallback(
        ({ formik }) => {
            let buttonText = 'You have not filled guests information';

            if (formik.isValid || !formik.values.accepted) {
                if (isOnRequestOnly) {
                    buttonText = 'Send Booking Request';
                } else if (request.surrogateAgencyId) {
                    buttonText = surrogateButtonText;
                } else if (PAYMENT_METHODS.ACCOUNT === $payment.paymentMethod) {
                    buttonText = 'Book & Pay' + price(contract.rate.finalPrice);
                } else {
                    buttonText = 'Book & Confirm';
                }
            }

            return (
                <button type="submit" className={'button main' + __class(!formik.isValid, 'disabled')}>
                    {buttonText}
                </button>
            );
        },
        [
            request.surrogateAgencyId,
            $payment.paymentMethod,
            contract.rate.finalPrice,
            surrogateButtonText,
            isOnRequestOnly,
        ]
    );

    const surrogateModalContent = useMemo(() => {
        if (request.surrogateAgencyId && request.surrogateAgentId) {
            if (isRefundable) {
                return `You are about to create a booking for the active agency’s ${request.surrogateAgencyName} agent ${request.surrogateAgentName}. The ${request.surrogateAgencyName}’s agents will be able to pay for the booking using the methods available to them. Please confirm the booking creation.`;
            } else if (request.surrogateActiveAgencyContractKind === 'OfflineOrCreditCardPayments') {
                return `You are about to create a booking for the active agency ${request.surrogateAgencyName} with agent ${request.surrogateAgentName}. The payment will be done from the Happy Travel ${request.currency} account. Please confirm the booking creation.`;
            } else {
                return `You are about to create a booking for the active agency ${request.surrogateAgencyName} with agent ${request.surrogateAgentName}. The money will be immediately deducted from the active agency’s ${request.surrogateAgencyName} virtual account. Please confirm the booking creation.`;
            }
        }
        return null;
    }, [
        request.surrogateAgencyId,
        request.surrogateActiveAgencyContractKind,
        request.surrogateAgentId,
        request.surrogateAgentName,
        request.currency,
        request.surrogateAgencyName,
    ]);

    const onFormSubmit = (values, formikBag, addons) => {
        const submit = () => taskSubmitBookingForm(values, formikBag, addons, navigate);

        if (surrogateModalContent) {
            window._surrogateBookingSubmit = submit;
            setModalVisibility(true);
        } else {
            submit();
        }
    };

    const SpecialRequest =
        (baseInfo.specialRequestsSupport === 'PartiallySupported' ||
            baseInfo.specialRequestsSupport === 'FullySupported') &&
        ((request.surrogateActiveAgencyContractKind === 'OfflineOrCreditCardPayments' && !isRefundable) ||
            !request.surrogateAgencyId)
            ? ({ formik }) => (
                  <div>
                      <div>
                          <FieldTextarea
                              formik={formik}
                              id="specialRequest"
                              label="Special Request"
                              placeholder="Add Special Request Here"
                              maxLength={250}
                          />
                      </div>
                      <div style={{ marginTop: 12 }}>
                          Please note that the special request is not guaranteed from Happytravel.com side
                      </div>
                  </div>
              )
            : null;

    return (
        <div className="booking block">
            <CustomSearchNotification />
            <div className="hide">{JSON.stringify($payment.paymentMethod)}</div>
            <section>
                <Breadcrumbs backText="Back to Room Selection" backLink="/search/contract" />
                <CachedForm
                    id={FORM_NAMES.BookingForm}
                    cacheValidator={(cache) => {
                        if (cache?.room?.length !== initialValues?.room.length) return false;
                        for (let i = 0; i < initialValues?.room.length; i++)
                            if (
                                cache?.room?.[i]?.passengers?.length !==
                                initialValues?.room[i].passengers.length
                            )
                                return false;
                        return true;
                    }}
                    initialValues={initialValues}
                    validationSchema={accommodationBookingValidator}
                    onSubmit={onFormSubmit}
                    render={(formik) => (
                        <div className="billet-wrapper">
                            <div className="billet">
                                <BookingSummary
                                    details={accommodation}
                                    contract={contract}
                                    checkInDate={baseInfo.checkInDate}
                                    checkOutDate={baseInfo.checkOutDate}
                                    numberOfNights={$accommodation.search.numberOfNights}
                                    numberOfGuests={request.adultsTotal + request.childrenTotal}
                                />
                                {$auth.permitted('CreateBooking') &&
                                Boolean(contract.availablePaymentMethods.length) &&
                                !isApiClient ? (
                                    <>
                                        {isOnRequestOnly && (
                                            <>
                                                <div className="accent-frame" style={{ marginBottom: 0 }}>
                                                    <div className="data only">
                                                        Please note, that this booking is available on request
                                                        only.
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!isOnRequestOnly && (
                                            <PaymentMethodSelector
                                                contractPaymentMethods={contract.availablePaymentMethods}
                                            />
                                        )}
                                        {!!contract.priceChangedAlert && (
                                            <div className="accent-frame">
                                                <div className="data only">
                                                    <div>Please note the booking price has changed</div>
                                                    To speed up a search on a large number of accommodations,
                                                    we use preloaded data. Sometimes the data may become
                                                    outdated while you work with the site. When this happens,
                                                    you may see a change in price or in cancellation policies
                                                    on this screen. The last shown price is final.
                                                    <ContinueButton formik={formik} />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {!contract.priceChangedAlert && (
                                                <ContinueButton formik={formik} />
                                            )}
                                        </div>
                                        <div className="checkbox-holder">
                                            <FieldCheckbox
                                                formik={formik}
                                                id="accepted"
                                                label={
                                                    <>
                                                        I have read and accepted
                                                        <Link
                                                            target="_blank"
                                                            to="/terms"
                                                            className="underlined link"
                                                            onClick={(event) => event.stopPropagation()}
                                                        >
                                                            Terms & Conditions
                                                        </Link>
                                                    </>
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ marginTop: 40, fontSize: '15px', lineHeight: '24px' }}>
                                        Please contact your Happy Travel manager if you want to book this
                                        accommodation
                                    </div>
                                )}
                            </div>
                            {!isApiClient ? (
                                <div className="another">
                                    {formik.isSubmitting && <Loader page />}
                                    <div className="form">
                                        <FieldArray
                                            render={() =>
                                                contract?.rooms.map((room, r) => (
                                                    <div className="room-container" key={r}>
                                                        <h2>
                                                            <GroupRoomTypesAndCount text contracts={[room]} />
                                                        </h2>
                                                        {Boolean(contract.availablePaymentMethods.length) && (
                                                            <table className="room-details">
                                                                <tbody>
                                                                    <FieldArray
                                                                        render={() =>
                                                                            formik.values.room[
                                                                                r
                                                                            ].passengers.map(
                                                                                (passengers, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            {index <
                                                                                            room.adultsNumber ? (
                                                                                                <FieldSelect
                                                                                                    formik={
                                                                                                        formik
                                                                                                    }
                                                                                                    id={`room.${r}.passengers.${index}.title`}
                                                                                                    placeholder="Select"
                                                                                                    label="Title"
                                                                                                    options={[
                                                                                                        {
                                                                                                            value: 'Mr',
                                                                                                            text: 'Mr.',
                                                                                                        },
                                                                                                        {
                                                                                                            value: 'Ms',
                                                                                                            text: 'Ms.',
                                                                                                        },
                                                                                                        {
                                                                                                            value: 'Miss',
                                                                                                            text: 'Miss',
                                                                                                        },
                                                                                                        {
                                                                                                            value: 'Mrs',
                                                                                                            text: 'Mrs.',
                                                                                                        },
                                                                                                    ]}
                                                                                                />
                                                                                            ) : (
                                                                                                <FieldChildren
                                                                                                    formik={
                                                                                                        formik
                                                                                                    }
                                                                                                    id={`room.${r}.passengers.${index}.title`}
                                                                                                    age={__plural(
                                                                                                        room
                                                                                                            .childrenAges[
                                                                                                            index -
                                                                                                                room.adultsNumber
                                                                                                        ],
                                                                                                        'year'
                                                                                                    )}
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <FieldText
                                                                                                formik={
                                                                                                    formik
                                                                                                }
                                                                                                id={`room.${r}.passengers.${index}.firstName`}
                                                                                                placeholder="Name"
                                                                                                label="First Name"
                                                                                                onChange={
                                                                                                    transliterate
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <FieldText
                                                                                                formik={
                                                                                                    formik
                                                                                                }
                                                                                                id={`room.${r}.passengers.${index}.lastName`}
                                                                                                placeholder="Surname"
                                                                                                label="Last Name"
                                                                                                onChange={
                                                                                                    transliterate
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                    {contract.rooms.length === 1 &&
                                                                    SpecialRequest ? (
                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <SpecialRequest
                                                                                    formik={formik}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                        <Allotment
                                                            contract={contract}
                                                            room={room}
                                                            checkInDate={baseInfo.checkInDate}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        />

                                        {contract?.rooms.length !== 1 && SpecialRequest ? (
                                            <div style={{ marginTop: 40 }}>
                                                <SpecialRequest formik={formik} />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : (
                                <div className="another" style={{ marginTop: 40 }}>
                                    <h1>Dear Partner,</h1>
                                    <p
                                        style={{
                                            margin: '30px 60px 30px 0',
                                            fontSize: '15px',
                                            lineHeight: '28px',
                                        }}
                                    >
                                        You are currently accessing the web version of Happy Travel using API
                                        client credentials. Please note that this type of access only supports
                                        search functionality and does not allow for bookings. If you would
                                        like to enable booking functionality on the web platform, we recommend
                                        inviting an additional agent to your agency. For any questions or
                                        further assistance, please do not hesitate to reach out to your
                                        account manager.
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                />
            </section>
            <SurrogateBookingConfirmationModal
                isOpen={modalVisibility}
                onClose={() => setModalVisibility(false)}
                modalContent={surrogateModalContent}
                buttonText={surrogateButtonText}
            />
        </div>
    );
});

export default AccommodationBookingPage;
