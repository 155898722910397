import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { PAYMENT_METHODS } from 'enum';
import { FORM_NAMES } from 'components/form';
import { $accommodation, $notifications, $payment, $ui } from 'stores';

const taskSubmitBookingForm = (values, formikBag, { isSubmitting, setSubmitting }, navigate) => {
    if (isSubmitting) return;
    setSubmitting(true);

    const contract = $accommodation.selected.roomContractSet;
    let roomDetails = [];
    const isOnRequestOnly = Boolean(contract.rooms.find((item) => item.isAvailableImmediately === false));

    for (let i = 0; i < contract?.rooms?.length; i++) {
        const adults = contract?.rooms[i]?.adultsNumber;
        const total = adults + contract?.rooms[i]?.childrenAges.length;
        let passengers = [];

        for (let j = 0; j < total; j++)
            passengers.push({
                title: values.room[i].passengers[j].title,
                firstName: values.room[i].passengers[j].firstName,
                lastName: values.room[i].passengers[j].lastName,
                age: j < adults ? null : contract?.rooms[i]?.childrenAges[j - adults],
                ...(j === 0 ? { isLeader: true } : {}),
            });

        roomDetails.push({
            type: contract.rooms[i]?.type,
            passengers,
        });
    }

    const request = {
        searchId: $accommodation.search.id,
        htId: $accommodation.selected.accommodation.htId,
        evaluationToken: $accommodation.selected.accommodationFinal.evaluationToken,
        roomContractSetId: contract.id,
        paymentMethod: $payment.paymentMethod,
        mainPassengerName:
            roomDetails[0].passengers[0].title +
            '. ' +
            roomDetails[0].passengers[0].firstName.trim() +
            ' ' +
            roomDetails[0].passengers[0].lastName.trim(),
        roomDetails: roomDetails,
        features: [],
        itineraryNumber: $ui.getItnCode(),
        surrogateAgencyId: $accommodation.search.request.surrogateAgencyId,
        surrogateAgentId: $accommodation.search.request.surrogateAgentId,
        supplier: $accommodation.selected.accommodation.source,
        ...($accommodation.search.request.customPrice && $accommodation.search.request.surrogateAgencyId
            ? {
                  totalPrice: {
                      amount: $accommodation.search.request.customPrice,
                      currency: $accommodation.search.request.currency,
                  },
                  decreaseMarkupReason: $accommodation.search.request.decreaseMarkupReason,
              }
            : {}),
        specialRequest: values.specialRequest,
    };
    $accommodation.setBookingRequest(request);

    if (isOnRequestOnly) {
        API.post({
            url: apiMethods.BOOK_SEND_REQUEST,
            body: request,
            success: () => {
                $notifications.addNotification(
                    'Your booking request was sent to the Happy Travel Reservations Team. Our manager will contact you shortly.',
                    'Booking request was sent!',
                    'success'
                );
                $ui.dropFormCache(FORM_NAMES.BookingForm);
                $ui.resetItnMode();
                setSubmitting(false);
                navigate('/search');
            },
            error: () => setSubmitting(false),
        });
        return;
    }

    if ([PAYMENT_METHODS.ACCOUNT, PAYMENT_METHODS.OFFLINE].includes($payment.paymentMethod))
        API.post({
            url:
                PAYMENT_METHODS.ACCOUNT === $payment.paymentMethod
                    ? apiMethods.BOOK_BY_ACCOUNT
                    : apiMethods.BOOK_FOR_OFFLINE,
            body: request,
            success: (result) => {
                if (!result?.bookingDetails?.referenceCode) {
                    $notifications.addNotification('Error occurred during account payment');
                    return;
                }
                $payment.setPaymentResult(null);
                $payment.setSubject(result.bookingDetails.referenceCode);
                $ui.dropFormCache(FORM_NAMES.BookingForm);
                $ui.resetItnMode();
                navigate('/accommodation/confirmation');
            },
            error: () => setSubmitting(false),
        });

    if ($payment.paymentMethod === PAYMENT_METHODS.CARD)
        API.post({
            url: apiMethods.ACCOMMODATION_BOOKING,
            body: request,
            success: (result) => {
                $payment.setSubject(
                    result,
                    $accommodation.selected.roomContractSet.rate.creditCardPrice ||
                        $accommodation.selected.roomContractSet.rate.finalPrice,
                    PAYMENT_METHODS.CARD
                );
                $ui.resetItnMode();
                navigate('/payment/form');
            },
            error: () => setSubmitting(false),
        });
};

export default taskSubmitBookingForm;
