import React from 'react';
import ModalTemplate from 'common/template/modal-template';

const SurrogateBookingConfirmationModal = (props) => {
    if (!props.isOpen) return null;

    const submit = () => {
        props.onClose();
        window._surrogateBookingSubmit();
    };

    return (
        <ModalTemplate {...props}>
            <div style={{ fontSize: '110%', lineHeight: '180%', marginBottom: 20 }}>{props.modalContent}</div>

            <div className="bottom">
                <button className="button main" onClick={submit} style={{ marginBottom: 15 }}>
                    {props.buttonText}
                </button>
                <button className="button" onClick={props.onClose}>
                    Cancel
                </button>
            </div>
        </ModalTemplate>
    );
};

export default SurrogateBookingConfirmationModal;
