import React, { useState } from 'react';
import { observer } from 'mobx-react';
import EditSpecialRequestModal from '../confirmation/edit-special-request-modal';
import { $auth, $notifications } from 'stores';

const BookingSpecialRequestPart = observer(({ isService, booking, loadBooking }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [remainingSpecialRequestCount, setRemainingSpecialRequestCount] = useState(
        booking.remainingSpecialRequestCount ?? 3
    );

    const isLimitedSpecialRequest = booking.supplier === 'Bronevik';

    if (isService || !booking?.isSpecialRequestsSupported) {
        return null;
    }

    if (
        booking.surrogateAgentId &&
        !booking.useOldSurrogateFlow &&
        booking.surrogateAgentId !== $auth.information?.id
    ) {
        return null;
    }

    return (
        <div className="actions">
            <EditSpecialRequestModal
                booking={booking}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onSuccess={() => {
                    if (booking.remainingSpecialRequestCount !== null) {
                        setRemainingSpecialRequestCount((prevState) => {
                            const newState = prevState - 1;

                            $notifications.addNotification(
                                `Special Request Updated! Please note that you can only change a special request up to three times per booking. ${
                                    newState
                                        ? `You have ${newState} modifications remaining`
                                        : 'You cannot modify the special request any further'
                                }`,
                                'Special Request Updated!',
                                'success'
                            );

                            return newState;
                        });
                    }

                    loadBooking();
                    setModalOpen(false);
                }}
            />
            <button
                className={
                    'button main' +
                    __class(isLimitedSpecialRequest && !remainingSpecialRequestCount, 'disabled')
                }
                onClick={() => setModalOpen(true)}
            >
                {booking.specialRequest ? 'Update Special Request to Hotel' : 'Add Special Request to Hotel'}
            </button>
        </div>
    );
});

export default BookingSpecialRequestPart;
