import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { fillEmptyAgentSettings } from 'tasks/utils/agent-settings';
import { forgetInvite } from 'tasks/signup/invitation';
import { FORM_NAMES } from 'components/form';
import { $notifications, $auth, $ui } from 'stores';
import OidcInstance from 'htcore/auth/oidc-instance';

const afterSuccessfulRegistration = (navigate) => {
    setTimeout(() => {
        OidcInstance()
            .removeUser()
            .then(() =>
                OidcInstance()
                    .signinSilent()
                    .then(() =>
                        API.get({
                            url: apiMethods.AGENT,
                            success: (agent) => {
                                if (agent?.email) {
                                    $auth.setInformation(agent);
                                    $notifications.addNotification(
                                        'Registration Completed!',
                                        'Great!',
                                        'success'
                                    );
                                }
                                fillEmptyAgentSettings();
                                forgetInvite();
                                $ui.dropFormCache(FORM_NAMES.RegistrationAgentForm);
                                $ui.dropFormCache(FORM_NAMES.RegistrationCompanyForm);
                            },
                        })
                    )
            );
        navigate('/search');
    }, 2000);
};

export const registerAgency = (form, setLoading, successCallback) => {
    API.post({
        url: apiMethods.REGISTRATION_REQUEST,
        body: {
            ...form,
            lastName: form.lastName ? form.lastName : '-',
            companyAddress: [form.companyCity, form.companyCountry].join(', '),
        },
        noAuth: true,
        success: successCallback,
        error: () => setLoading(false),
    });
};

export const registerAgent = (agentForm, setLoading, invitationData, invitationCode, navigate) => {
    API.post({
        url: apiMethods.AGENT_REGISTER,
        body: {
            registrationInfo: {
                ...agentForm,
                email: invitationData.email,
            },
            invitationCode: invitationCode,
        },
        success: () => afterSuccessfulRegistration(navigate),
        error: () => setLoading(false),
    });
};
