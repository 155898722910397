import React, { useState } from 'react';
import { FieldText, FieldSelect } from 'components/form';
import { Loader } from 'components/simple';
import DefaultTableRow from './default-table-row';

const Table = ({
    columns,
    list,
    textEmptyResult,
    textEmptyList,
    onRowClick,
    filter,
    sorters,
    searches,
    CustomFilter = null,
    TableRow = DefaultTableRow,
    searchText,
    linesLimit,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('Creation Date');

    const searcher = (result) => {
        if (searchQuery) {
            result = result.filter((item) => {
                if (typeof item !== 'object') {
                    return false;
                }
                const found = (values) => {
                    const queries = searchQuery
                        .toLowerCase()
                        .split(' ')
                        .filter((v) => v.length);
                    for (let i = 0; i < queries.length; i++) {
                        if (
                            !values.some(
                                (v = '') => typeof v === 'string' && v.toLowerCase().indexOf(queries[i]) >= 0
                            )
                        ) {
                            return false;
                        }
                    }
                    return true;
                };
                return found(searches(item));
            });
        }
        return result;
    };

    const sorter = (result) => {
        const func = sorters?.find((sorter) => sorter.title === sortBy)?.sorter;

        if (func) {
            result.sort((a, b) => {
                let x = func(a);
                let y = func(b);

                if (x?.toLowerCase) x = x.toLowerCase();
                if (y?.toLowerCase) y = y.toLowerCase();
                if (x < y) return 1;
                if (x > y) return -1;
                return 0;
            });
        }
        return result;
    };

    const getResult = () => {
        if (!list?.length) return [];
        let result = list.filter(() => true);
        if (filter) result = filter(result);
        result = sorter(result);
        result = searcher(result);

        if (linesLimit) {
            result = result.slice(0, linesLimit);
        }

        return result;
    };

    const result = getResult();

    return (
        <div className="table">
            <div>
                <div className="controls">
                    <div className="left">
                        {sorters && (
                            <div className="form">
                                <FieldSelect
                                    id="sorter"
                                    label="Sort by"
                                    value={sortBy}
                                    setValue={(value) => setSortBy(value)}
                                    options={sorters.map((item) => ({
                                        text: item.title,
                                        value: item.title,
                                    }))}
                                />
                            </div>
                        )}
                        {CustomFilter}
                    </div>
                    {searches && (
                        <div className="form search-wrap">
                            <FieldText
                                id="search"
                                label={searchText || 'Search'}
                                placeholder="Find in list..."
                                onChange={(event) => setSearchQuery(event.target.value)}
                                onClear={() => setSearchQuery('')}
                                Icon={<span className="icon icon-search" />}
                                clearable
                                value={searchQuery}
                            />
                        </div>
                    )}
                </div>
                {list === null ? (
                    <Loader />
                ) : !result?.length ? (
                    <div className="empty">
                        {list?.length ? textEmptyResult : textEmptyList || textEmptyResult}
                    </div>
                ) : (
                    <table className="the-table">
                        <tbody>
                            {result.map(
                                (row, index) =>
                                    row && (
                                        <TableRow
                                            key={index}
                                            row={row}
                                            onRowClick={onRowClick}
                                            columns={columns}
                                        />
                                    )
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default Table;
