import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { API, useTitle } from 'htcore';
import { windowSessionStorage } from 'htcore/misc/storage';
import apiMethods from 'common/api-methods';
import { date } from 'simple';
import Table from 'components/table/table';
import NavigationBar from 'components/navigation/navigation-bar';
import { FieldCheckbox } from 'components/form';
import NestedTableRow from 'components/table/nested-table-row';
import { loadAgency } from 'tasks/utils/agent-settings';
import { Columns, Searches, Sorters, ParentRowColumns, GroupSorters } from './table-data';
import { $auth } from 'stores';

const groupData = (list) => {
    const itineraryData = list
        .filter(({ itineraryBookingsCount }) => itineraryBookingsCount > 1)
        .sort((a, b) => b.id - a.id);

    const resultObject = {};

    const createList = (listData, isService) => {
        listData.forEach((item) => {
            const { itineraryNumber, checkInDate, checkOutDate, price } = item;
            const group = resultObject?.[itineraryNumber];

            if (group) {
                group.children.push({ ...item, isServiceCell: isService });
                group.dates.push({ checkInDate, checkOutDate });

                const isSameCurrency = group.currency === price.currency && group.total;
                resultObject[itineraryNumber].total = isSameCurrency ? group.total + price.amount : null;
            } else {
                resultObject[itineraryNumber] = {
                    ...item,
                    total: price.amount,
                    currency: price.currency,
                    dates: [{ checkInDate, checkOutDate }],
                    children: [{ ...item, isServiceCell: isService }],
                };
            }

            item?.services && createList(item.services, true);
        });
    };

    createList(itineraryData);

    return Object.values(resultObject).map(({ children, ...rest }) => {
        return { ...rest, children: children.sort((a, b) => b.id - a.id) };
    });
};

const BOOKINGS_PAGE_SIZE = 30;

export const BOOKING_LINK = 'booking_link';

const AgencyBookingsManagementPage = observer(() => {
    useTitle('Bookings');
    const { filterTab = 'all', filterSubtab } = useParams();
    const [agentIdFilter, setAgentIdFilter] = useState(null);
    const [offlineFilter, setOfflineFilter] = useState(false);
    const [bookings, setBookings] = useState(null);
    const [howManyToShow, setHowManyToShow] = useState(BOOKINGS_PAGE_SIZE);
    const [pageSize, setPageSize] = useState(0);
    const navigate = useNavigate();

    const isGroupedData = filterTab === 'itinerary';
    const isService = filterTab === 'service';

    useEffect(() => {
        setBookings(null);
        loadAgency();
        if ($auth.permitted('ViewAgencyBookings'))
            API.get({
                url: apiMethods.AGENCY_BOOKINGS_LIST,
                after: (list) => {
                    if (list?.length) {
                        setBookings(
                            list.map((item) => ({
                                ...item.data,
                                agent: item.agent,
                            }))
                        );
                    } else {
                        setBookings([]);
                    }
                },
            });
        else
            API.get({
                url: apiMethods.BOOKING_LIST,
                success: (list) => {
                    setBookings(list);
                },
            });
    }, [$auth.permitted('ViewAgencyBookings')]);

    const filter = (list) => {
        if (!list) return null;

        if ('other-agencies' === filterTab) {
            list = list.filter((item) => item.surrogateAgencyName && !item.useOldSurrogateFlow);
            if ('active' === filterSubtab) {
                list = list.filter(({ surrogateAgencyAgentName }) => surrogateAgencyAgentName);
            }
            if ('surrogate' === filterSubtab) {
                list = list.filter(({ surrogateAgencyAgentName }) => !surrogateAgencyAgentName);
            }
        } else if ($auth.activeAgency?.canUseSurrogateAgency) {
            list = list.filter((item) => !(item.surrogateAgencyName && !item.useOldSurrogateFlow));
        }

        let result = list;

        if ('cancelled' === filterTab) {
            result = list.filter((item) => 'Cancelled' === item.status);
        } else if ('attention' === filterTab) {
            result = list.filter(
                (item) => 'ManualCorrectionNeeded' === item.status || 'Pending' === item.status
            );
        } else if ('future' === filterTab) {
            result = list
                .filter(
                    (item) =>
                        'ManualCorrectionNeeded' === item.status ||
                        'Pending' === item.status ||
                        'Confirmed' === item.status
                )
                .filter((item) => !date.isPast(item.checkInDate));
        } else if ('completed' === filterTab) {
            result = list.filter((item) => 'Completed' === item.status);
        } else if ('rejected' === filterTab) {
            result = list.filter((item) => 'Rejected' === item.status);
        } else if ('discarded' === filterTab) {
            result = list.filter((item) => 'Discarded' === item.status);
        } else if ('itinerary' === filterTab) {
            result = groupData(list);
        } else if ('confirmed' === filterTab) {
            result = list.filter((item) => 'Confirmed' === item.status);
        } else if ('service' === filterTab) {
            result = list
                .map((item) => item.services.map((service) => ({ ...service, agent: item.agent })))
                .flat();
        }

        if (agentIdFilter !== null) {
            result = result.filter(
                (item) => item.agent?.id === agentIdFilter || item.surrogateAgencyAgentId === agentIdFilter
            );
        }
        if (offlineFilter && !isService) {
            result = result.filter(
                (item) => !!item.isOffline || !!item?.children?.find((child) => child?.isOffline)
            );
        }

        setPageSize(result.length);
        setHowManyToShow(BOOKINGS_PAGE_SIZE);

        return result;
    };

    useEffect(() => {
        windowSessionStorage.set(BOOKING_LINK, filterTab);
    }, [filterTab]);

    const list = useMemo(() => {
        return filter(bookings);
    }, [agentIdFilter, filterTab, filterSubtab, bookings, offlineFilter, isService]);

    const permittedAgency = $auth.permitted('ViewAgencyBookings');

    const areBookingDocumentsAvailable = [
        'all',
        'confirmed',
        'future',
        'attention',
        'completed',
        'itinerary',
    ].includes(filterTab);

    const navigationTabs = [
        { text: 'All Bookings', value: 'all', link: '/bookings' },
        { text: 'Service Bookings', value: 'service', link: '/bookings/service' },
        { text: 'Confirmed Bookings', value: 'confirmed', link: '/bookings/confirmed' },
        { text: 'Itinerary Bookings', value: 'itinerary', link: '/bookings/itinerary' },
        { text: 'Future', value: 'future', link: '/bookings/future' },
        { text: 'Need Attention', value: 'attention', link: '/bookings/attention' },
        { text: 'Completed', value: 'completed', link: '/bookings/completed' },
        { text: 'Cancelled', value: 'cancelled', link: '/bookings/cancelled' },
        { text: 'Rejected', value: 'rejected', link: '/bookings/rejected' },
        { text: 'Discarded', value: 'discarded', link: '/bookings/discarded' },
    ];

    if ($auth.activeAgency?.canUseSurrogateAgency) {
        navigationTabs.push({
            text: 'For Other Agencies',
            value: 'other-agencies',
            link: '/bookings/other-agencies/active',
        });
    }

    return (
        <div className="management block">
            <section className="sticky-header">
                <h2>{permittedAgency ? 'Agency Bookings' : 'Bookings'}</h2>
            </section>
            <div className="sticky-header-before" />
            <NavigationBar active={filterTab} items={navigationTabs} />

            {filterTab === 'other-agencies' ? (
                <div className="cabinet-header">
                    <div className="cabinet-main-navigation">
                        <section style={{ maxWidth: '1440px', padding: '0 24px' }}>
                            <NavLink to="/bookings/other-agencies/active">For Active Agencies</NavLink>
                            <NavLink to="/bookings/other-agencies/surrogate">For Surrogate Agencies</NavLink>
                        </section>
                    </div>
                </div>
            ) : null}

            <section className="content agency">
                <InfiniteScroll
                    dataLength={howManyToShow}
                    next={() => setHowManyToShow(howManyToShow + BOOKINGS_PAGE_SIZE)}
                    hasMore={pageSize > howManyToShow}
                    loader={null}
                >
                    <Table
                        columns={Columns(
                            permittedAgency,
                            areBookingDocumentsAvailable,
                            setAgentIdFilter,
                            isGroupedData,
                            isService,
                            filterSubtab
                        )}
                        list={list}
                        textEmptyResult="No Bookings Found"
                        textEmptyList="No Bookings Found"
                        onRowClick={(item) => navigate(`/booking/${item.referenceCode}`)}
                        sorters={isGroupedData ? GroupSorters() : Sorters(permittedAgency)}
                        searches={Searches(permittedAgency)}
                        linesLimit={howManyToShow}
                        TableRow={
                            isGroupedData
                                ? (props) =>
                                      NestedTableRow({
                                          ...props,
                                          parentColumns: ParentRowColumns(setAgentIdFilter),
                                      })
                                : undefined
                        }
                        CustomFilter={
                            permittedAgency && (
                                <div className="agent-filter">
                                    {!agentIdFilter || agentIdFilter === $auth.information.id ? (
                                        <FieldCheckbox
                                            label="Show Only My Bookings"
                                            value={!!agentIdFilter}
                                            onChange={(value) =>
                                                setAgentIdFilter(value ? $auth.information.id : null)
                                            }
                                        />
                                    ) : (
                                        <div>
                                            <span className="link" onClick={() => setAgentIdFilter(null)}>
                                                Clear Filters
                                            </span>
                                        </div>
                                    )}

                                    {!isService && filterSubtab !== 'active' && (
                                        <FieldCheckbox
                                            label="Offline Bookings Only"
                                            value={!!offlineFilter}
                                            onChange={setOfflineFilter}
                                        />
                                    )}
                                </div>
                            )
                        }
                    />
                </InfiniteScroll>
            </section>
        </div>
    );
});

export default AgencyBookingsManagementPage;
